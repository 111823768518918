import React, { useState, useEffect } from 'react';
import './App.css';

function Home({ user, handleLogout }) {
  return (
    <div className="home-container">
      <h2 className='"bienvenido'>Bienvenido, {user}!</h2>
        <div className="logout">
         <button type='btn-logout' onClick={handleLogout} >Cerrar sesión</button>
        </div>
    </div>
  );
}



function LoginForm({ handleLogin, generateAuthToken }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://154.56.61.20:3001/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
        credentials: 'same-origin', // Include credentials (cookies) in the request
      });
      const data = await response.json();
      if (response.ok) {
        handleLogin(username, password);
        generateAuthToken(); // Generate authentication token after successful login
      } else {
        setError(data.error);
      }
    } catch (error) {
      setError('Error al iniciar sesión');
    }
  };

  return (
    <form onSubmit={handleFormSubmit} className='login'>
      <br />
      <h1>Iniciar sesión </h1>
      <br />
      <label>Usuario:</label>
      <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      <label>Contraseña:</label>
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <button type="submit">Iniciar sesión</button>
      {error && <p>{error}</p>}
    </form>
  );
}

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState('');
  const [authToken, setAuthToken] = useState('');

  const generateAuthToken = () => {
    const token = crypto.getRandomValues(new Uint32Array(2)).join('');
    setAuthToken(token);
  };

  useEffect(() => {
    const checkLoggedInStatus = async () => {
      try {
        const response = await fetch('http://154.56.61.20:3001/checkLoggedInStatus', {
          credentials: 'same-origin', // Include credentials (cookies) in the request
        });
        const data = await response.json();
        if (response.ok && data.loggedIn) {
          setLoggedIn(true);
          setUser(data.username);
        }
      } catch (error) {
        console.error('Error al verificar el estado de inicio de sesión');
      }
    };
    checkLoggedInStatus();
  }, []);

  const handleLogin = (username, password) => {
    setLoggedIn(true);
    setUser(username);
  };

  const handleLogout = async () => {
    try {
      await fetch('http://154.56.61.20:3001/logout', {
        credentials: 'same-origin', // Include credentials (cookies) in the request
      });
      setLoggedIn(false);
      setUser('');
      setAuthToken(''); // Clear authentication token on logout
    } catch (error) {
      console.error('Error al cerrar sesión');
    }
  };

  return (
    <div>
      {loggedIn ? (
        <Home user={user} handleLogout={handleLogout} />
      ) : (
        <LoginForm handleLogin={handleLogin} generateAuthToken={generateAuthToken} />
      )}
    </div>
  );
}

export default App;
